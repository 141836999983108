import PropTypes from 'prop-types';
import React from 'react';

import Form from '../components/form/form';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './index.module.css';

const IndexPage = props => (
  <div className={styles.root}>
    <Layout>
      <SEO />
      <Form location={props.location} />
    </Layout>
  </div>
);

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
