import axios from 'axios';

import { env } from '../environment';

const baseUrl = `${env.api.auth}/v1/auth`;

export function getMagicLink(email, targetAppId, redirectUrl) {
  return axios.post(`${baseUrl}/magic-link`, {
    email,
    appId: env.applicationId,
    extra: {
      targetAppId,
      redirectUrl,
    },
  });
}
