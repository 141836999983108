import TextField from '@material-ui/core/TextField';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createValidator, getPayload } from 'validator-creator';

import { env } from '../../environment.js';
import Logo from '../../images/logo.png';
import * as api from '../../webservices/auth-api';
import Success from '../success';
import styles from './form.module.css';
import { required, validEmail } from './validation-rules';

const urlRegex = /https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const validate = createValidator({
  email: [required, validEmail],
});

const initialState = {
  email: '',
  errors: {},
  success: false,
};

function Form(props) {
  const searchParams = new URLSearchParams(props.location.search);
  const redirectUrl = searchParams.get('redirect_url');
  const appId = searchParams.get('appId');

  useEffect(() => {
    if (
      (redirectUrl === null || !urlRegex.test(redirectUrl) || appId === null) &&
      env.platform !== 'local'
    ) {
      navigate('/404', { replace: true }); // In useEffect because window is'nt available during server side rendering
    }
  });

  const [formState, setFormState] = useState(initialState);

  const { email, errors, success } = formState;

  async function handleChange(event) {
    const {
      target: { name, value },
    } = event;

    const change = {
      [name]: value,
    };

    const validationResult = await validate(change);

    setFormState({
      ...formState,
      ...change,
      errors: { ...errors, ...getPayload(validationResult) },
      ...success,
    });
  }

  async function handleSubmit(evt) {
    evt.preventDefault();

    const validationResult = await validate(formState);
    if (validationResult[0].length !== 0) {
      setFormState({
        ...formState,
        errors: { ...errors, ...getPayload(validationResult) },
        ...success,
      });
      return;
    }

    try {
      await api.getMagicLink(email, appId, redirectUrl);
      setFormState({
        ...formState,
        ...errors,
        success: true,
      });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }

  if (!success) {
    return (
      <form className={styles.form} onSubmit={evt => handleSubmit(evt)}>
        <div className={styles.title}>
          <img
            rel="icon"
            className={styles.title__logo}
            alt="logo-sso"
            src={Logo}
          />
          <div className={styles.title__content}>
            <p className={styles.title__content__main}>Wizbii Tools</p>
            <p className={styles.title__content__sub}>
              <strong>Portail de connexion</strong>
            </p>
          </div>
        </div>
        <div className={styles.separator}></div>
        <h1 className={styles.formTitle}>Connexion</h1>
        <p>
          {' '}
          Pour continuer, saisissez votre adresse e-mail dans le champ
          ci-dessous. Vous recevrez un magic link pour continuer votre
          navigation.
        </p>
        <TextField
          className={styles.field}
          label="Adresse email"
          name="email"
          defaultValue={email}
          onChange={evt => handleChange(evt)}
          error={!!errors.email}
          helperText={errors.email}
          variant="outlined"
        />

        <button type="submit" className={styles.btn}>
          Recevoir un lien
        </button>
      </form>
    );
  }
  return <Success />;
}

Form.propTypes = {
  location: PropTypes.object,
};

export default Form;
